<template>
  <div
    class="contentWrap"
    :style="{
      backgroundImage:
        'url(' +
        $returnColor().path +
        ($store.state.selfData.member_id
          ? '/home-page_bg.png'
          : '/home-page_bg.png') +
        ')',
    }"
  >
    <!-- dialog -->
    <!-- dialog -->
    <!-- top -->
    <!-- top -->
    <van-cell-group inset style="margin: 0 0 15px 0; width: 295px">
      <div class="tWrap">
        <p class="title chF">金额统计</p>
        <div class="wrapContent">
          <div class="top">
            <div class="wrapTitle">
              <p class="chF">余额</p>
              <p class="numF">¥ {{ stats.balance | thousands }}</p>
            </div>
            <div class="wrapTitle">
              <p class="chF">红利</p>
              <p class="numF">¥ {{ stats.bonus | thousands }}</p>
            </div>
          </div>

          <div class="bottom" v-if="stats.bonus">
            <div class="tContent">
              <div class="tContentWrap">
                <p class="chF">今日总红利</p>
                <p class="numF">
                  ¥{{ stats.bonus_stats[0].today | thousands }}
                </p>
              </div>
              <div class="tContentWrap">
                <p class="chF">昨日总红利</p>
                <p class="numF">
                  ¥{{ stats.bonus_stats[0].yesterday | thousands }}
                </p>
              </div>
            </div>
            <div class="tContent">
              <div class="tContentWrap">
                <p class="chF">今日完成笔数</p>
                <p class="numF">{{ stats.payment.count | thousands }}</p>
              </div>
              <div class="tContentWrap">
                <p class="chF">今日完成金额</p>
                <p class="numF">¥{{ stats.payment.amount | thousands }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-cell-group>
    <keep-alive>
      <div style="width: 295px">
        <div class="tSelectWrap">
          <span @click="getMethod = '0'" :class="{ active: getMethod == '0' }">
            待付款
          </span>
          <span @click="getMethod = '1'" :class="{ active: getMethod == '1' }">
            已完成
          </span>
          <span @click="getMethod = '2'" :class="{ active: getMethod == '2' }"
            >已驳回
          </span>
          <span @click="getMethod = '3'" :class="{ active: getMethod == '3' }">
            待审核
          </span>
          <span @click="getMethod = '5'" :class="{ active: getMethod == '5' }">
            审核失败
          </span>
          <van-icon name="search" @click="$router.push('/orderPRecord')" />
        </div>

        <div
          class="cardWrap"
          v-for="order in pOrdersData"
          :key="order.order_id"
          v-show="order.bank_name == '支付宝'"
        >
          <div class="left">
            <img
              v-if="
                order.content &&
                Array.isArray(order.content) &&
                order.content.length
              "
              :src="order.content[0].img"
              alt=""
            />
            <div class="orderContent">
              <div class="amountWrap">
                <div>
                  <p style="color:color: #969799;font-size:12px">金额</p>
                  <p class="amount">
                    ¥ {{ String(order.amount).split(".00")[0] }}
                  </p>
                </div>

                <div class="tag">
                  <span style="white-space: nowrap"
                    ><van-icon name="balance-o" style="font-size: 16px" />{{
                      order.status_ch
                    }}</span
                  >
                </div>
                <!-- <van-tag
                  style="padding: 5px"
                  :type="returnTagType(order.status)"
                  >{{ order.status_ch }}</van-tag
                > -->
              </div>
              <p
                class="boder-top"
                v-if="
                  order.content &&
                  Array.isArray(order.content) &&
                  order.content.length
                "
                style="overflow: hidden; padding-top: 5px; color: #646566"
              >
                {{ order.content[0].name }}
                <span
                  style="display: none"
                  v-if="
                    order.content &&
                    Array.isArray(order.content) &&
                    order.content.length
                  "
                >
                  {{ (order.name = order.content[0].name) }}
                  {{ (order.img = order.content[0].img) }}
                  {{ (order.price = order.content[0].price) }}
                </span>
                <span v-else>
                  {{ order.content }}
                </span>
              </p>
            </div>
          </div>
          <p
            v-if="order.member_id && order.release_at"
            style="
              white-space: nowrap;
              padding-top: 10px;
              color: red;
              font-size: 12px;
            "
          >
            {{ $returnTime(order.release_at, "all") }} 前点开确认否则释出
          </p>
          <div class="bottom">
            <div class="bottomBtn">
              <p v-if="order.member_id" @click="checkPOrder(order)">
                查看详细内容
              </p>
              <p style="color: #1989fa" v-else @click="grabPOrder(order)">
                抢单
              </p>
            </div>
          </div>
        </div>
      </div>
    </keep-alive>
    <foor />
  </div>
</template>
<script>
import { mapState } from "vuex";
import foor from "../../components/footer";
export default {
  components: {
    foor,
  },
  data() {
    return {
      setOrderModal: false,
      orderForm: {},
      currentOrder: {},
      getMethod: "0",
      stats: {
        payment: {},
      },
    };
  },
  computed: {
    ...mapState(["orders", "pOrders", "isReLoadding", "except"]),
    pOrdersData() {
      let data = this.$store.state.pOrders;
      return data.data
        .sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        })
        .sort((c, d) => {
          return d.member_id - c.member_id;
        });
    },
  },
  watch: {
    getMethod() {
      this.$store.dispatch("getPOrder", { page: 1, status: this.getMethod });
      this.$route.params.id = this.getMethod;
      this.$route.query.id = this.getMethod;
    },
    isReLoadding() {
      if (this.isReLoadding) {
        if (this.getMethod == "order") {
          this.$store.dispatch("getOrder");
        } else {
          this.$store.dispatch("getPOrder");
        }
      }
    },
  },
  methods: {
    returnTagType(status) {
      if (status == 0) return "warning";
      if (status == 1) return "success";
      return "danger";
    },
    grabPOrder(order) {
      let vm = this;
      this.$dialog.confirm({
        title: "抢单",
        message: `抢单金额${order.amount}`,
        beforeClose(method, done) {
          if (method == "confirm") {
            vm.$http
              .post(`/mobile/payments/${order.order_id}/catch`)
              .then((res) => {
                if (res.data.code == 0) {
                  vm.$toast.success("抢单成功");
                  vm.$store.dispatch("getPOrder", {
                    page: 1,
                    status: vm.getMethod,
                  });
                  done();
                } else {
                  done();
                }
              });
          } else {
            done();
          }
        },
      });
    },
    getStats() {
      this.$http.get(`/mobile/stats`).then((res) => {
        if (res.data.code == 0) {
          this.stats = res.data.data;
        }
      });
    },
    checkOrder(order) {
      this.$router.push(`/orderCInfo/${order.order_id}`);
    },
    checkPOrder(order) {
      this.$router.push({
        name: "orderPInfo",
        params: order,
        query: order,
      });
      // this.$http.get(`/mobile/payments/${order.order_id}`).then((res) => {
      //   if (res.data.code == 0) {
      //     Object.keys(res.data.data).forEach((e) => {
      //       order[e] = res.data.data[e];
      //     });
      //     this.$router.push(`/orderPInfo`);
      //   }
      // });
    },
    scrollPage() {
      let params = {};
      if (window.scrollY + window.screen.height >= document.body.scrollHeight) {
        if (this.pOrders.next_page) {
          params.status = this.getMethod;
          params.page = this.pOrders.current_page * 1 + 1;
          this.$store.dispatch("getPOrder", params);
        }
      }
    },
  },
  created() {
    // if (this.$route.query.getMethod) {
    //   this.getMethod = this.$route.query.getMethod;
    // }
    if (this.$route.query.getMethod) {
      this.getMethod = this.$route.query.getMethod;
    }
    this.getStats();
    this.$store.dispatch("getPOrder", { page: 1, status: this.getMethod });
  },
  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollPage);
  },
};
</script>
<style lang="scss" scoped>
.numF {
  font-family: "Montserrat" !important;
}
.cardFooter {
  display: flex;
  button {
    width: 50%;
  }
}
.dialogInfo {
  padding: 0 5px;
  color: rgb(219, 99, 101);
  font-size: 14px;
}
.selectBox {
  width: 80px;
  height: 80px;
  background-color: #fff;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #9d9b9b;
  font-size: 10px;
  // padding: 20px;
}
.selectBox.active {
  border: 1px solid black;
  color: black;
}
.tWrap {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 20px;
  flex-direction: column;
  .title {
    font-size: 11pt;
  }
  // flex-wrap: wrap;
  .wrapContent {
    display: flex;
    flex-direction: column;
  }
  .top {
    display: flex;
    width: 100%;
    padding: 5px;
  }
  .wrapTitle {
    font-weight: 500;
    font-size: 18px;
    width: 50%;
    text-align: center;
    &:first-child {
      border-right: 1px solid rgba(#cccccc, 0.3);
    }
    &:last-child {
      // border-left: 0.5px solid rgba(#cccccc, 0.3);
    }
    p:first-child {
      font-size: 14pt;
    }
    p.numF {
      font-size: 18px;
      margin-top: 5px;
    }
  }
  .bottom {
    display: flex;
    width: 100%;
    padding-top: 5px;
    border-top: 1px solid rgba(#9d9b9b, 0.3);
    .tContent {
      width: 50%;
      &:first-child {
        border-right: 1px solid rgba(#9d9b9b, 0.3);
      }
      .tContentWrap {
        .numF {
          margin-top: 10px;
        }
        p {
          &:first-child {
            color: #9d9b9b;
          }
        }
        .numF {
          font-size: 16px;
        }
      }
    }
  }
}
.tSelectWrap {
  width: 100%;
  height: 35px;
  border-radius: 20px;
  background-color: #fff;
  margin-bottom: 15px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
    line-height: 35px;
    margin-right: 10px;
    white-space: nowrap;
    color: black;
    &.active {
      color: red;
      border-bottom: 2px solid black;
    }
  }
  i {
    margin-left: auto;
    margin-right: 15px;
    font-size: 20px;
  }
}
.contentWrap {
  padding: 20px;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
}
.contentWrap {
  // background-image: url("/home-page_bg.png");
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: top center;
  width: 100vw;
  background-size: cover;
  background-attachment: fixed;
}
.cardWrap {
  background-color: #fff;
  border-radius: 5px;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  // .left {
  //   display: flex;
  //   align-items: center;
  //   p {
  //     width: 120px;
  //     font-size: 12px;
  //     font-weight: bold;
  //     &:last-child {
  //       color: #cccccc;
  //     }
  //   }
  //   img {
  //     width: 28px;
  //     height: 28px;
  //     margin-left: 15px;
  //     margin-right: 15px;
  //   }
  // }

  .left {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    p {
      width: 120px;
      font-size: 12px;
      font-weight: bold;
      &.boder-top {
        // color: #cccccc;
        border-top: 1px solid rgba(220, 236, 225, 1);
        width: 100%;
      }
    }
    img {
      width: 80px;
      height: 80px;
      // margin-left: 15px;
      margin-right: 15px;
    }
  }
  .bottom {
    width: 100%;
    margin-top: 15px;
    border-top: 1px solid rgba(gray, 0.3);
    .bottomBtn {
      text-align: center;
      padding: 5px 0;
      color: #4dcca2;
    }
  }
  .right {
    .orderContent {
      display: flex;
      font-weight: bold;
      p {
        margin-right: 15px;
      }
      button {
        margin-right: 10px;
        font-size: 12px;
      }
    }
  }
}
.cardWrap {
  background-color: #fff;
  border-radius: 5px;
  // height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding: 10px;
  flex-direction: column;
  padding-bottom: 5px;
  .amount {
    color: #f15a24;
    font-size: 14px;
  }
  .orderContent {
    width: 100%;
  }
  .amountWrap {
    display: flex;
    // flex-direction: column
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
}
.marginTop {
  margin-top: 8px;
}

.tag {
  background-color: rgb(255, 244, 228);
  padding: 2px 8px;
  font-weight: bold;
  color: rgb(251, 199, 42);
  border-radius: 100px;
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 0px;
}
</style>
